@import './variables';

.ui.card {
    padding: 15px;
    margin: 0;
    width: auto;
    box-shadow: 2px 2px 8px 0 rgba(151, 151, 151, 0.6);
    background-color: $white;
    border-radius: 2px;
    border: none;

    @media screen and (max-width: 767px) {
        padding: 10px;
    }
}

.ui.modal {
    // TODO look at impact?
    position: relative;

    .content {
        height: 100%;
        padding: 28px 50px 31px 50px;
    }
}

.ui.table {
    margin-top: 0;
    border: none;
    border-radius: 0;
}

.ui.table tfoot th {
    background: white;
}

.ui.table td, .ui.table thead th, .ui.table tfoot th {
    padding-left: 15px;
    padding-right: 0px;

    &.no-padding {
        padding: 0px;
    }
}

.ui.table td:last-child, .ui.table thead th:last-child, .ui.table tfoot th:last-child {
    padding-right: 15px;
}

.ui.divider {
    &.no-margin {
        margin: 0px;
    }
}

.ui.table {
    thead {
        th {
            font-weight: 500;
            background: $neutral-5-color !important;
            border-left: none !important;
            border-radius: 0px !important;

            &:hover {
                background: $neutral-5-color;
            }

            &.no-sort {
                cursor: default;
            }
        }
    }
}

.ui.scrolling.dropdown .menu {
    &.dd-menu {
        min-width: 250px !important;
    }

    &.no-max-height {
        max-height: none !important;
    }
}

.ui.dimmer {
    background-color: rgba(0, 0, 0, .65);
}

.Toastify__toast {
    font-family: $font-family !important;
    box-shadow: 2px 2px 8px 0 $neutral-3-color !important;
    background-color: $neutral-3-color !important;
    border-radius: 5px !important;
    padding: 28px 20px !important;
}

.Toastify__toast--default {
    background-color: $neutral-3-color !important;
}

.Toastify__toast--success {
    background-color: $success-color !important;
}

.Toastify__toast--warning {
    background-color: $warning-color !important;
}

.Toastify__toast--error {
    background-color: $error-color !important;
}

@media only screen and (max-width: 767px) {
    .ui.modal {
        width: 100%;
        margin: 0;
    }

    .ui.scrolling.dropdown .menu {
        width: 100% !important;
        max-height: inherit !important;
    }
}

.ui.modal {
    .close.icon {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}