@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.base-transports-card {
  padding: 0px !important;
}
.base-transports-card .transports-header {
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .base-transports-card .transports-header {
    padding: 10px;
  }
}
.base-transports-card .transports-header .filter-button, .base-transports-card .transports-header .export-button {
  width: 80px;
}
.base-transports-card .transports-header .filter-button {
  margin-right: 10px;
}
.base-transports-card .footer .footer-page-info {
  color: #777777;
  font-size: 14px;
  line-height: 21px;
}
.base-transports-card .footer .previous-button {
  margin-right: 10px;
}
.base-transports-card .download-button {
  margin-left: 10px;
}

.mobile-single-transport {
  padding: 10px;
}
.mobile-single-transport .amount {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.mobile-single-transport .address {
  font-size: 13px;
  line-height: 20px;
}
.mobile-single-transport .date {
  font-size: 12px;
  color: #626262;
}

.overview-container {
  height: 100%;
}
.overview-container .confirmed-transports-card {
  padding: 0px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .overview-container .confirmed-transports-card {
    margin-top: 10px;
  }
}
.overview-container .confirmed-transports-card .transports-header {
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .overview-container .confirmed-transports-card .transports-header {
    padding: 10px;
  }
}
.overview-container .confirmed-transports-card .new-transport-link {
  margin-right: 15px;
}
.overview-container .confirmed-transports-card .confirmed-transports-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
@media screen and (max-width: 767px) {
  .overview-container .confirmed-transports-card .confirmed-transports-title {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .overview-container .confirmed-transports-card .buttons-container {
    width: 100%;
  }
}
.overview-container .download-button {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .overview-container .ui.card.info-card {
    margin-right: inherit;
    margin-top: 10px !important;
  }
}