.selected-discount{
    color: green;
    font-size: 16px;
    margin-bottom: 20px;
    svg{
        font-size: 24px;
    }
    .sd-description{
        margin-left: 10px;
    }
}
.add-discount-button{
    margin-left: 10px;
}