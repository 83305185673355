.language-label {
    margin-bottom: 5px;
}

.only-mobile, .larger-than-mobile{
    display: none;
}

@media screen and (max-width: 767px) {
    .only-mobile{
        display: inherit;
    }
}

@media screen and (min-width: 768px) {
    .larger-than-mobile{
        display: inherit;
    }
}

.only-on-tablet{
    display: none;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .only-on-tablet{
        display: inherit;
    }
}

.update-button {
    margin-top: 10px;
}