
@import '../../../styles/variables';

.select-button{
    width: 100%;
    cursor: pointer;
    background-color: $white;
    border-radius: 2px;
    padding: 10px;     
    border: $light-border; 
    color: $neutral-3-color;  
    .label-text{
        flex-grow: 1;
        margin-right: 10px;
        line-height: 21px;
    }
    .sb-icon{
        color: $neutral-4-color;
    }
    &.has-selected{
        background-color: $primary-color;
        .label-text{
            color: $white;
        }
        .sb-icon{
            color: $white;
        }
    }
}