@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.header-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .header-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ui.modal.default-modal .content .blank-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.blank-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .blank-container {
    margin-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.blank-container .blank-container-buttons {
  margin-top: 40px;
}
.blank-container .blank-container-buttons .previous-button {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .blank-container .blank-container-buttons {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    border-top: 1px solid #EDEDED;
  }
  .blank-container .blank-container-buttons .df-row-ac {
    width: 100%;
    justify-content: space-between;
  }
  .blank-container .blank-container-buttons .df-row-ac .primary-button {
    width: auto;
  }
}

.blank-mobile-footer {
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  border-top: 1px solid #EDEDED;
}

.step5-container {
  font-size: 16px;
  line-height: 25px;
}
@media screen and (max-width: 767px) {
  .step5-container {
    font-size: 14px;
    line-height: 21px;
  }
}
.step5-container .step5-card {
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
}
.step5-container .descr {
  font-weight: 600;
  margin-top: 20px;
}
.step5-container .price-comment {
  font-size: 14px;
  line-height: 21px;
  color: #9e9e9e;
}
.step5-container .save-as-template-text {
  margin-right: 10px;
}
.step5-container .save-as-template-button {
  margin-top: 20px;
}
.step5-container .stored-as-template-icon {
  color: #F6B93B;
}
.step5-container .resolve-button {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}