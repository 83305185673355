@import '../../../styles/variables';

.header-container {
    padding: 40px;
    width: 100%;
    position: sticky;
    top: 0px;
    background: $white;
    z-index: 1;

    @media screen and (max-width: 767px) {
        padding: 0px;
    }

    .left {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
    }

    .right {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        justify-content: flex-end;
    }

    .save-as-draft-button {
        margin-right: 20px;

        @media screen and (max-width: 767px) {
            margin-right: inherit;
            color: $neutral-2-color;
        }
    }

    .cancel-button {
        @media screen and (max-width: 767px) {
            color: $neutral-2-color;
        }
    }

    .mobile-cancel-button {
        color: $neutral-2-color;
    }

    .saved-as-draft {
        margin-right: 10px;

        .status-text {
            color: $neutral-1-color;
            font-size: 16px;
            line-height: 25px;
        }
    }
}