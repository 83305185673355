@import '../../../styles/variables';

.draft-detail-card{
    .label{
        color: $neutral-3-color;
        font-size: 14px;
        line-height: 21px;
        &:not(.without-margin){
            margin-top: 20px;
        }
        @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
        }
    }
    .info{
        font-size: 16px;
        line-height: 25px;
        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }
}