@import '../../../styles/variables';

.filter-trigger {
    padding: 6px;
    // margin-left: 6px;
    margin-right: 6px;

    &:hover {
        background: rgba(177, 177, 177, 0.133);
        border-radius: 8px;
    }

    .ft-icon {
        background: $neutral-4-color;
        margin-right: 7px;
        color: white;
        border-radius: 4px;
        height: 18px;
        width: 18px;
        font-size: 10px;

        &.ft-icon-selected {
            background: $success-color;
        }
    }

    .ft-label {
        position: absolute;
        // background: rgb(226, 5, 5);
        background: $error-color;
        text-align: center;
        width: 16px;
        height: 16px;
        font-size: 11px;
        border-radius: 100px;
        left: 0;
        top: 0;
        line-height: 16px;
        // margin-left: 5px;
    }
}

.dd-menu {
    min-width: 250px !important;
    max-height: none !important;
}


.top-level-div {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}