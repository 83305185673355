.drafts-card{
    padding: 0px !important;
    .header{
        padding: 15px;
        @media screen and (max-width: 767px) {
            padding: 10px;
        }
    }
    .actions-buttons{
        button:last-child{
            margin-left: 10px;
            @media screen and (max-width: 767px) {
                margin-left: inherit;
            }
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }
    .mobile-single-draft{
        .reference{
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        .info{
            font-size: 13px;
            line-height: 20px;
        }
    }
}