@import '../../../styles/variables';

.full-screen-loader {
    position: absolute;
    z-index: 2;
    // background: $secondary-2-color;
    // opacity: 0.85;
    background-color: rgba(239,245,251,0.85);
    // &.dim-background {
    //     opacity: 0.85;
    // }
}