@import '../../styles/variables';

.dashboard-search-box {
    border: $light-border;
    background-color: $white;
    box-shadow: 2px 2px 8px 0 $neutral-4-color;
    border-radius: 2px;
    padding: 10.5px 14.5px;
    color: $neutral-3-color;
    font-size: 12px;
    line-height: 12px;

    @media screen and (max-width: 767px) {
        border: none;
        background-color: $neutral-6-color;
        width: 100%;
        padding-left: 5px;

        input {
            background-color: $neutral-6-color;
        }
    }
}

.go-back-button {
    @media screen and (max-width: 767px) {
        background-color: $neutral-6-color;
        color: $neutral-3-color;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        border-bottom: 1px solid $neutral-4-color;
        font-size: 12px;
        line-height: 12px;
    }
}