@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.ui.card {
  padding: 15px;
  margin: 0;
  width: auto;
  box-shadow: 2px 2px 8px 0 rgba(151, 151, 151, 0.6);
  background-color: #ffffff;
  border-radius: 2px;
  border: none;
}
@media screen and (max-width: 767px) {
  .ui.card {
    padding: 10px;
  }
}

.ui.modal {
  position: relative;
}
.ui.modal .content {
  height: 100%;
  padding: 28px 50px 31px 50px;
}

.ui.table {
  margin-top: 0;
  border: none;
  border-radius: 0;
}

.ui.table tfoot th {
  background: white;
}

.ui.table td, .ui.table thead th, .ui.table tfoot th {
  padding-left: 15px;
  padding-right: 0px;
}
.ui.table td.no-padding, .ui.table thead th.no-padding, .ui.table tfoot th.no-padding {
  padding: 0px;
}

.ui.table td:last-child, .ui.table thead th:last-child, .ui.table tfoot th:last-child {
  padding-right: 15px;
}

.ui.divider.no-margin {
  margin: 0px;
}

.ui.table thead th {
  font-weight: 500;
  background: #EDEDED !important;
  border-left: none !important;
  border-radius: 0px !important;
}
.ui.table thead th:hover {
  background: #EDEDED;
}
.ui.table thead th.no-sort {
  cursor: default;
}

.ui.scrolling.dropdown .menu.dd-menu {
  min-width: 250px !important;
}
.ui.scrolling.dropdown .menu.no-max-height {
  max-height: none !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.65);
}

.Toastify__toast {
  font-family: "Poppins", sans-serif !important;
  box-shadow: 2px 2px 8px 0 #9e9e9e !important;
  background-color: #9e9e9e !important;
  border-radius: 5px !important;
  padding: 28px 20px !important;
}

.Toastify__toast--default {
  background-color: #9e9e9e !important;
}

.Toastify__toast--success {
  background-color: #4EB064 !important;
}

.Toastify__toast--warning {
  background-color: #F6B93B !important;
}

.Toastify__toast--error {
  background-color: #EB2F06 !important;
}

@media only screen and (max-width: 767px) {
  .ui.modal {
    width: 100%;
    margin: 0;
  }
  .ui.scrolling.dropdown .menu {
    width: 100% !important;
    max-height: inherit !important;
  }
}
@media screen and (min-width: 768px) {
  .ui.modal .close.icon {
    display: none;
  }
}

.df-row {
  display: flex;
  flex-direction: row;
}

.df-row-ac {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .df-col-if-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .df-col-ac-if-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .hide-if-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .df-col-reverse-if-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.df-row-ab {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.df-row-afs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.df-row-afs-left {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}

.df-row-afs-right {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.df-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df-col-ab {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.df-col {
  display: flex;
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.jc-se {
  justify-content: space-evenly;
}

.sticky-at-top {
  position: sticky;
  top: 0;
}

.sticky-at-bottom {
  position: sticky;
  bottom: 0;
}

.stretch-ver {
  height: 100%;
}

.stretch-hor {
  width: 100%;
}

.tac {
  text-align: center;
}

.fg1 {
  flex-grow: 1;
}

.font-bold {
  font-weight: 600;
}

.font-error {
  color: #EB2F06;
}

.font-success {
  color: #4EB064;
}

.pos-rel {
  position: relative;
}

.ml-10px {
  margin-left: 10px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-20px {
  margin-right: 20px;
}

.z-index-1 {
  z-index: 1;
}

.overflow-auto {
  overflow: auto;
}

.padding-10px {
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .jc-c-if-mobile {
    justify-content: center;
  }
}

html {
  display: flex;
  width: 100%;
}
html body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

html, body, #root {
  height: 100%;
  overflow: auto;
}

input, textarea, select, button {
  font-family: "Poppins", sans-serif;
}