.uf-container {
    .uf-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .uf-description {
        font-size: 16px;
        white-space: pre-line;
        margin-bottom: 10px;
    }

    .uf-download {
        margin-top: 10px;
    }

    .overlay-loader {
        left: 0px;
        top: 0px;
    }
}