@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700");
.header-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .header-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ui.modal.default-modal .content .blank-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.blank-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .blank-container {
    margin-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.blank-container .blank-container-buttons {
  margin-top: 40px;
}
.blank-container .blank-container-buttons .previous-button {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .blank-container .blank-container-buttons {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
    border-top: 1px solid #EDEDED;
  }
  .blank-container .blank-container-buttons .df-row-ac {
    width: 100%;
    justify-content: space-between;
  }
  .blank-container .blank-container-buttons .df-row-ac .primary-button {
    width: auto;
  }
}

.blank-mobile-footer {
  position: sticky;
  bottom: 0px;
  background: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  border-top: 1px solid #EDEDED;
}

@media screen and (max-width: 767px) {
  .vehicle-options {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .vehicle-option-container {
    margin-bottom: 20px;
    width: 100%;
  }
}
.vehicle-option-container .vehicle-option-item {
  cursor: pointer;
  height: 151px;
  width: 151px;
  border: 1px solid #DFDFDF;
  border-radius: 2px;
}
@media screen and (max-width: 767px) {
  .vehicle-option-container .vehicle-option-item {
    width: 100%;
  }
}
.vehicle-option-container .vehicle-option-item .vehicle-name {
  margin-top: 10px;
  color: #9e9e9e;
  font-size: 14px;
}
.vehicle-option-container .vehicle-option-item.invalid {
  border: 1px solid #EB2F06;
}
.vehicle-option-container .vehicle-option-item.has-selected {
  border: 1px solid #DE192B;
}
.vehicle-option-container .vehicle-option-item.has-selected .vehicle-name {
  font-weight: 600;
  color: #2E2D2E;
}
.vehicle-option-container .vehicle-description {
  color: #9e9e9e;
  font-size: 12px;
  line-height: 16px;
}