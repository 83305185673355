.confirmation-modal {
    .confirmation-modal-inner {
        flex-grow: 1;
    }

    .mc-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .mc-description {
        flex-grow: 1;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .cancel-button {
        margin-right: 20px;
        white-space: nowrap;
        
        @media screen and (max-width: 767px) {
            margin-right: inherit;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }
}