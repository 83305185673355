@import '../../../styles/variables';

.field-group {
    display: flex;
    flex-direction: column;

    &:not(.no-margin) {
        margin-bottom: 20px;
    }

    label {
        line-height: 18px;
        font-size: 16px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    input::placeholder {
        font-size: 14px;
    }

    input,
    select {
        box-sizing: border-box;
        border: $default-border;
        border-radius: 2px;
        font-size: 18px;
        line-height: 18px;
        padding: 11.5px 8.5px;
        color: $neutral-1-color;
        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 16px;
        }
    }

    .error-message {
        font-size: 14px;
        color: $error-color;
        padding-top: 5px;
        white-space: pre-line;
    }

    .label-container {
        margin-bottom: 3px;

        .optional-tag {
            color: $neutral-3-color;
            font-size: 14px;
        }
    }
}