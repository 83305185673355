@import '../../../styles/variables';

.mobile-footer-container{
    padding: 10px;
    justify-content: space-evenly;
    span{
        font-size: 10px;
    }
    svg{
        // margin-bottom: 5px;
        height: 30px;
    }
    .nav-link{
        color: $neutral-3-color;
    }
    .active{
        .nav-link{
            color: $neutral-1-color;
        }
        .regular-icon{
            display: none;
        }
    }    
    .nav-link:not(.active){
        .selected-icon{
            display: none;
        }
    }
}