@import '../../../styles/variables';

.transport-detail-card {
    .download-button {
        margin-left: 10px;
    }

    .confirmed-container {
        margin-top: 20px;
    }

    .label {
        color: $neutral-3-color;
        font-size: 14px;
        line-height: 21px;

        &:not(.without-margin) {
            margin-top: 20px;
        }

        @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .info {
        font-size: 16px;
        line-height: 25px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    .cargo {
        margin-left: 20px;
    }

    .cargo-infos {
        margin-left: 10px;
    }

    .cargo-info {
        line-height: 21px;
    }

    .file-number {
        margin-left: 10px;
        color: $neutral-1-color;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
    }

    .single-price-calc {
        margin-right: 20px;
    }

    .stop {
        margin-bottom: 10px;
    }

    .export-button {
        margin-left: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .status {
        padding: 7px 15px 7px 15px;
        border-radius: 100px;
        color: white;

        &.confirmed {
            background-color: $success-color;
        }

        &:not(.confirmed) {
            background-color: $neutral-4-color;
        }

        .status-text {
            margin-left: 5px;
            line-height: 21px;
        }
    }
}