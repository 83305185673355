@import "../../styles/variables";

// sticky implecations.
.container {
    height: 100%;

    .body-wrapper {
        overflow: auto;
        width: 100%;
    }
}

.container {
    .side-nav {
        background-color: $secondary-2-color;
        padding: 20px 35px 20px 15px;
        height: 100%;
    }

    .nav-links {
        margin-top: 6px;

        a {
            cursor: pointer;
            color: $neutral-2-color;
            text-decoration: none;

            &.active {
                font-weight: 600;
                color: $primary-color;
            }
        }

        a.sub-nav-link {
            font-size: 16px;
            margin-top: 10px;
        }

        a.upper-nav-link {
            margin-top: 25px;
            font-size: 16px;
        }

        a.lower-nav-link {
            font-size: 14px;
            margin-top: 15px;
        }

        .company-name {
            font-weight: 600;
            font-size: 14px;
        }

        .sub-nav-link {
            margin-left: 10px;
        }
    }

    .body-wrapper {
        background: $secondary-2-color;

        .top-bar {
            background: $secondary-2-color;
            padding: 20px 20px 5px 20px;

            .search-anything {
                flex-grow: 1;
                margin-right: 20px;
                max-width: 450px;
            }

            .user-text {
                font-size: 14px;
                line-height: 21px;
                margin-left: 15px;
            }
        }

        .body-content {
            padding: 20px;
        }
    }
}

$nav-footer-height: 90px;

//mobile!
@media screen and (max-width: 767px) {
    .container {

        .mobile-menu {
            background: $white;
            padding: 20px;
        }

        .top-bar-mobile {
            padding: 15px 20px;
            background: $white;
            border-bottom: 1px solid $neutral-4-color;

            .user-text-mobile {
                font-size: 14px;
                font-weight: 600;
            }
        }

        .mobile-body-wrapper {
            background: $secondary-2-color;
            overflow: auto;
            flex-grow: 1;
        }

        .nav-footer {
            background: $white;
            // min-height: $nav-footer-height;
            border-top: 1px solid $neutral-4-color;
            position: sticky;
            bottom: 0px;
            flex-shrink: 0;
        }
    }
}