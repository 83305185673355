@import '../../../styles/variables';
.vs-image-container {
    width: 90%;
    margin-left: auto; 
    margin-right: auto;
}

.vs-image-container img {
    width: 100%;
}

.vehicle-description {
    color: $neutral-3-color;
    font-size: 12px;
    line-height: 16px;
}

