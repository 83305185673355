@import '../../../styles/variables';



// .Toastify__toast.Toastify__toast--success{
//   background-color: $success-color !important;
// }

.toast-notification{
  white-space: pre-line;
  font-family: $font-family;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
