@import '../../../../styles/variables';

.select-from-addressbook-container {
    .selected-address {
        font-size: 16px;
        line-height: 25px;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }

        .font-bold {
            font-size: 16px;
            line-height: 25px;
        }

        .label {
            font-size: 14px;
            color: $neutral-3-color;
        }
    }
}