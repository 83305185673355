.cargo-ops-container{
    .add-button{
        text-align: left;
    }   
    .load-or-unload-text{
        font-size: 18px;
        font-weight: 600;
    }
    .error-message{
        font-size: 14px;
        color: #EB2F06;
        padding-top: 5px;
    }
}