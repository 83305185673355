.base-templates-card{
    padding: 0px !important;
    .title{
        padding: 15px;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        @media screen and (max-width: 767px) {
            padding: 10px;
        }   
    }
    .footer{
        .footer-page-info{
            color: #777777;
            font-size: 14px;
            line-height: 21px;
        }
        .previous-button{
            margin-right: 10px;
        }
    }
    .download-button{
        margin-left: 10px;
    }
    .action-buttons-container{
        button:last-child{
            margin-left: 10px;
            @media screen and (max-width: 767px) {
                margin-left: inherit;
            }
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }
    .mobile-single-template{
        .reference{
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        .info{
            font-size: 13px;
            line-height: 20px;
        }
    }
}