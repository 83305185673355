@import '../../styles/variables';

.valid-green-icon{
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background-color: $success-color;
    border: none;
    color: $white;
    padding: 2px;

    .valid-svg-icon {
        width: 12px;
        height: 12px;
    }
}