@import '../../../../styles/variables';

.single-bulletin {
    cursor: pointer;

    .bulletin-title {
        font-size: 15px;
        line-height: 23px;

        @media screen and (max-width: 767px) {
            font-size: 13px;
            line-height: 20px;
        }
    }

    .bulletin-more-info {
        font-size: 15px;
        line-height: 23px;

        @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
        }
    }
}