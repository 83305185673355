@import '../../../styles/variables';

.base-transports-card{
    padding: 0px !important;
    .transports-header{
        padding: 15px;
        @media screen and (max-width: 767px) {
            padding: 10px;
        }
        .filter-button, .export-button{
            width: 80px;
        }
        .filter-button{
            margin-right: 10px;
        }
    }
    .footer{
        .footer-page-info{
            color: #777777;
            font-size: 14px;
            line-height: 21px;
        }
        .previous-button{
            margin-right: 10px;
        }
    }
    .download-button{
        margin-left: 10px;
    }
}

.mobile-single-transport{
    padding: 10px;
    .amount{
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .address{
        font-size: 13px;
        line-height: 20px;
    }
    .date{
        font-size: 12px;
        color: $neutral-2-color;
    }
}